import React, { useState, useEffect } from 'react';
import { Table, TableCell, Paper, TableHead, TableRow, TableBody, TableContainer } from '@material-ui/core';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import CheckIcon from '@material-ui/icons/Check';
import SearchIcon from '@material-ui/icons/Search';
import { useMediaQuery } from 'react-responsive';
import Modal from 'react-bootstrap/Modal';
import Select from 'react-select'; // Import react-select
import axios from 'axios';
import enviroments from './enviroments.js';

import './IframeCobertura.style.scss';

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#C3000A",
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
    padding: "15px 10px",
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 200,
  },
});

const initialStatePlano = {
  "Sigla": "",
  "PlanoSaudeId": "",
  "Convenio": "",
  "Nome": "",
  "InternacaoUrgenciaPompeia": "",
  "InternacaoUrgenciaSantana": "",
  "InternacaoUrgenciaIpiranga": "",
  "InternacaoUrgenciaCentroMedico": "",
  "InternacaoEletivaPompeia": "",
  "InternacaoEletivaSantana": "",
  "InternacaoEletivaIpiranga": "",
  "InternacaoEletivaCentroMedico": "",
  "PSAdultoPompeia": "",
  "PSAdultoSantana": "",
  "PSAdultoIpiranga": "",
  "PSAdultoCentroMedico": "",
  "PSInfantilPompeia": "",
  "PSInfantilSantana": "",
  "PSInfantilIpiranga": "",
  "PSInfantilCentroMedico": "",
  "ExamesPompeia": "",
  "ExamesSantana": "",
  "ExamesIpiranga": "",
  "ExamesCentroMedico": "",
  "ConsultasPompeia": "",
  "ConsultasSantana": "",
  "ConsultasIpiranga": "",
  "ConsultasCentroMedico": "",
  "QuimioterapiaAmbulatorialPompeia": "",
  "QuimioterapiaAmbulatorialSantana": "",
  "QuimioterapiaAmbulatorialIpiranga": "",
  "QuimioterapiaAmbulatorialCentroMedico": "",
  "ColetaExamesLaboratoriaisPompeia": "",
  "ColetaExamesLaboratoriaisSantana": "",
  "ColetaExamesLaboratoriaisIpiranga": "",
  "ColetaExamesLaboratoriaisCentroMedico": "",
  "Observacao": "",
  "DataAtualizacao": "2020-01-23 14:33:02.547"
};

const IframeCobertura = () => {
  const classes = useStyles();
  const isMobile = useMediaQuery({ query: '(max-width: 900px)' });
  const [planos, setPlanos] = useState([]);
  const [convenio, setConvenio] = useState(null);
  const [plano, setPlano] = useState(null);
  const [planoSelected, setPlanoSelected] = useState(initialStatePlano);
  const [showTable, setShowTable] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const cleanPlanoSelected = () => setPlanoSelected(initialStatePlano);

  const handleConvenioSelected = (selectedOption) => {
    setShowTable(false);
    setConvenio(selectedOption);
    setPlano(null); // Reset the selected plan when a new convenio is selected
  };

  const handlePlanoSelected = (selectedOption) => {
    setShowTable(false);
    setPlano(selectedOption);
  };

  const handleSearchClick = () => {
    if (!plano) {
      setShowModal(true);
    } else {
      cleanPlanoSelected();
      setPlanoSelected(planos.find(el => el.Sigla === convenio.value && el.Nome === plano.value));
      setShowTable(true);
    }
  };

  const handleClose = () => {
    setShowModal(false);
  };

  useEffect(() => {
    async function getPlanos() {
      try {
        const { data } = await axios.get(enviroments.prd, {
          headers: {
            token: enviroments.token
          }
        });
        setPlanos(data);
      } catch (error) {
        console.log(error);
      }
    }

    getPlanos();
  }, []);

  // Lista de convênios (distintos)
  const planosDistinct = [...new Set(planos.map(item => item.Sigla))].sort().map(sigla => ({
    label: sigla,
    value: sigla,
  }));

  // Filtrar planos de acordo com o convênio selecionado
  const planosFiltered = planos.filter(el => el.Sigla === convenio?.value).sort((a, b) => a.Nome.localeCompare(b.Nome)).map(el => ({
    label: el.Nome,
    value: el.Nome,
  }));

  return (
    <>
      <section id="cobertura-page-section-iframe">
        {planos.length <= 0 ? (
          <div className="loader-container">
            <span className="loader"></span>
          </div>
        ) : (
          <div id="cobertura-content">
            <div id="planos-list-container">
              <h2 className="h2-title-red">Cobertura Planos de Saúde</h2>
              <p className='texto-suporte'>A Rede de Hospitais São Camilo de São Paulo atende uma ampla lista de planos de saúde. Consulte a relação e verifique a cobertura oferecida. Cada Operadora de Saúde possui um serviço diferenciado, portanto, verifique em qual unidade o seu plano é aceito e se o mesmo possui cobertura para o serviço desejado.</p>
              
              <div id="planos-list-combo-container">
                <Select
                  className="select-dropdown"
                  placeholder="Selecione o convênio"
                  value={convenio}
                  onChange={handleConvenioSelected}
                  options={planosDistinct}
                  menuPortalTarget={document.body}
                  isSearchable
                  styles={{
                    control: (base, state) => ({
                      ...base,
                      boxShadow: state.isFocused ? '0 0 0 1px red' : 'none',
                      borderColor: state.isFocused ? 'red' : base.borderColor,
                      '&:hover': {
                        borderColor: state.isFocused ? 'red' : base.borderColor
                      }
                    }),
                    option: (base, state) => ({
                      ...base,
                      backgroundColor: state.isFocused ? '#C3000A' : base.backgroundColor,
                      color: state.isFocused ? 'white' : base.color,
                    })
                  }}
                />

                <Select
                  className="select-dropdown"
                  placeholder="Selecione o plano"
                  value={plano}
                  onChange={handlePlanoSelected}
                  options={planosFiltered}
                  isSearchable
                  menuPortalTarget={document.body}
                  isDisabled={!convenio} // Disable the plan dropdown until a convenio is selected
                  styles={{
                    control: (base, state) => ({
                      ...base,
                      boxShadow: state.isFocused ? '0 0 0 1px red' : 'none',
                      borderColor: state.isFocused ? 'red' : base.borderColor,
                      '&:hover': {
                        borderColor: state.isFocused ? 'red' : base.borderColor
                      }
                    }),
                    option: (base, state) => ({
                      ...base,
                      backgroundColor: state.isFocused ? '#C3000A' : base.backgroundColor,
                      color: state.isFocused ? 'white' : base.color,
                    })
                  }}
                />

                <Modal show={showModal} onHide={handleClose}>
                  <Modal.Header closeButton>
                    <Modal.Title>IMPORTANTE!</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <p>Selecione um plano para prosseguir.</p>
                  </Modal.Body>
                </Modal>
                <SearchIcon id="search-icon" onClick={handleSearchClick} />
              </div>

              {/* Tabela ou Lista de resultados */}
              {showTable && planoSelected.PlanoSaudeId ? (
                isMobile ? (
                  /* Renderização para dispositivos móveis */
                  <ul id="card-list-mobile"> 
                    <li 
                      key={planoSelected.nome}
                      className='row-card-mobile'
                    >
                      <p className="title-card-mobile"><strong>Internação Urgência</strong></p>
                      <div className="inline-content-card-mobile">
                        <p>Ipiranga: {planoSelected.InternacaoUrgenciaIpiranga ? <CheckIcon id="check-green-icon"/> : ' '}</p>
                        <p>Pompeia: {planoSelected.InternacaoUrgenciaPompeia ? <CheckIcon id="check-green-icon"/> : ' '}</p>
                        <p>Santana: {planoSelected.InternacaoUrgenciaSantana ? <CheckIcon id="check-green-icon"/> : ' '}</p>
                        {/* <p>Centro Médico: {planoSelected.InternacaoUrgenciaCentroMedico ? <CheckIcon id="check-green-icon"/> : ' '}</p> */}
                      </div>
                    </li>
                    <li 
                      key={planoSelected.nome}
                      className='row-card-mobile-odd'
                    >
                      <p className="title-card-mobile"><strong>Internação Eletiva</strong></p>
                      <div className="inline-content-card-mobile">
                        <p>Ipiranga: {planoSelected.InternacaoEletivaIpiranga ? <CheckIcon id="check-green-icon"/> : ' '}</p>
                        <p>Pompeia: {planoSelected.InternacaoEletivaPompeia ? <CheckIcon id="check-green-icon"/> : ' '}</p>
                        <p>Santana: {planoSelected.InternacaoEletivaSantana ? <CheckIcon id="check-green-icon"/> : ' '}</p>
                        {/* <p>Centro Médico: {planoSelected.InternacaoEletivaCentroMedico ? <CheckIcon id="check-green-icon"/> : ' '}</p> */}
                      </div>
                    </li>
                    <li 
                      key={planoSelected.nome}
                      className='row-card-mobile'
                    >
                      <p className="title-card-mobile"><strong>Pronto Socorro Adulto</strong></p>
                      <div className="inline-content-card-mobile">
                        <p>Ipiranga: {planoSelected.PSAdultoIpiranga ? <CheckIcon id="check-green-icon"/> : ' '}</p>
                        <p>Pompeia: {planoSelected.PSAdultoPompeia ? <CheckIcon id="check-green-icon"/> : ' '}</p>
                        <p>Santana: {planoSelected.PSAdultoSantana ? <CheckIcon id="check-green-icon"/> : ' '}</p>
                        {/* <p>Centro Médico: {planoSelected.PSAdultoCentroMedico ? <CheckIcon id="check-green-icon"/> : ' '}</p> */}
                      </div>
                    </li>
                    <li 
                      key={planoSelected.nome}
                      className='row-card-mobile-odd'
                    >
                      <p className="title-card-mobile"><strong>Pronto Socorro Infantil</strong></p>
                      <div className="inline-content-card-mobile">
                        <p>Ipiranga: {planoSelected.PSInfantilIpiranga ? <CheckIcon id="check-green-icon"/> : ' '}</p>
                        <p>Pompeia: {planoSelected.PSInfantilPompeia ? <CheckIcon id="check-green-icon"/> : ' '}</p>
                        <p>Santana: {planoSelected.PSInfantilSantana ? <CheckIcon id="check-green-icon"/> : ' '}</p>
                        {/* <p>Centro Médico: {planoSelected.PSInfantilCentroMedico ? <CheckIcon id="check-green-icon"/> : ' '}</p> */}
                      </div>
                    </li>
                    <li 
                      key={planoSelected.nome}
                      className='row-card-mobile'
                    >
                      <p className="title-card-mobile"><strong>Exames de Imagem</strong></p>
                      <div className="inline-content-card-mobile">
                        <p>Ipiranga: {planoSelected.ExamesIpiranga ? <CheckIcon id="check-green-icon"/> : ' '}</p>
                        <p>Pompeia: {planoSelected.ExamesPompeia ? <CheckIcon id="check-green-icon"/> : ' '}</p>
                        <p>Santana: {planoSelected.ExamesSantana ? <CheckIcon id="check-green-icon"/> : ' '}</p>
                        {/* <p>Centro Médico: {planoSelected.ExamesCentroMedico ? <CheckIcon id="check-green-icon"/> : ' '}</p> */}
                      </div>
                    </li>
                    <li 
                      key={planoSelected.nome}
                      className='row-card-mobile-odd'
                    >
                      <p className="title-card-mobile"><strong>Consultas</strong></p>
                      <div className="inline-content-card-mobile">
                        <p>Ipiranga: {planoSelected.ConsultasIpiranga ? <CheckIcon id="check-green-icon"/> : ' '}</p>
                        <p>Pompeia: {planoSelected.ConsultasPompeia ? <CheckIcon id="check-green-icon"/> : ' '}</p>
                        <p>Santana: {planoSelected.ConsultasSantana ? <CheckIcon id="check-green-icon"/> : ' '}</p>
                        {/* <p>Centro Médico: {planoSelected.ConsultasCentroMedico ? <CheckIcon id="check-green-icon"/> : ' '}</p> */}
                      </div>
                    </li>
                    <li 
                      key={planoSelected.nome}
                      className='row-card-mobile'
                    >
                      <p className="title-card-mobile"><strong>Quimioterapia</strong></p>
                      <div className="inline-content-card-mobile">
                        <p>Ipiranga: {planoSelected.QuimioterapiaAmbulatorialIpiranga ? <CheckIcon id="check-green-icon"/> : ' '}</p>
                        <p>Pompeia: {planoSelected.QuimioterapiaAmbulatorialPompeia ? <CheckIcon id="check-green-icon"/> : ' '}</p>
                        <p>Santana: {planoSelected.QuimioterapiaAmbulatorialSantana ? <CheckIcon id="check-green-icon"/> : ' '}</p>
                        {/* <p>Centro Médico: {planoSelected.QuimioterapiaAmbulatorialCentroMedico ? <CheckIcon id="check-green-icon"/> : ' '}</p> */}
                      </div>
                    </li>
                    <li 
                      key={planoSelected.nome}
                      className='row-card-mobile-odd'
                    >
                      <p className="title-card-mobile"><strong>Exames laboratoriais</strong></p>
                      <div className="inline-content-card-mobile">
                        <p>Ipiranga: {planoSelected.ColetaExamesLaboratoriaisIpiranga ? <CheckIcon id="check-green-icon"/> : ' '}</p>
                        <p>Pompeia: {planoSelected.ColetaExamesLaboratoriaisPompeia ? <CheckIcon id="check-green-icon"/> : ' '}</p>
                        <p>Santana: {planoSelected.ColetaExamesLaboratoriaisSantana ? <CheckIcon id="check-green-icon"/> : ' '}</p>
                        {/* <p>Centro Médico: {planoSelected.ColetaExamesLaboratoriaisCentroMedico ? <CheckIcon id="check-green-icon"/> : ' '}</p> */}
                      </div>
                    </li>
                    <h8 className="h2-gray-center">Para informações quanto a cobertura, entrar em contato com nossa Central de Agendamento - (11) 3172-6800</h8>
                  </ul>
                ) : (
                  /* Renderização para dispositivos desktop */
                  <>
                    <div className="table-container">
                        <TableContainer component={Paper}>
                          <Table className={classes.table} aria-label="simple table">
                            <TableHead>
                              <StyledTableRow>
                                <StyledTableCell id="table-cell-3"><b>Especialidade</b></StyledTableCell>
                                <StyledTableCell align="center"><b>Ipiranga</b></StyledTableCell>
                                <StyledTableCell align="center"><b>Pompeia</b></StyledTableCell>
                                <StyledTableCell align="center"><b>Santana</b></StyledTableCell>
                                {/* <StyledTableCell align="center"><b>Centro Médico</b></StyledTableCell> */}
                              </StyledTableRow>
                            </TableHead>
                            <TableBody>
                              <StyledTableRow key={planoSelected.Nome}>
                                <StyledTableCell component="th" scope="row">
                                  Internação Urgência
                                </StyledTableCell>
                                <StyledTableCell align="center">{planoSelected.InternacaoUrgenciaIpiranga ? <CheckIcon id="check-green-icon"/> : ' '}</StyledTableCell>
                                <StyledTableCell align="center">{planoSelected.InternacaoUrgenciaPompeia ? <CheckIcon id="check-green-icon"/> : ' '}</StyledTableCell>
                                <StyledTableCell align="center">{planoSelected.InternacaoUrgenciaSantana ? <CheckIcon id="check-green-icon"/> : ' '}</StyledTableCell>
                                {/* <StyledTableCell align="center">{planoSelected.InternacaoUrgenciaCentroMedico ? <CheckIcon id="check-green-icon"/> : ' '}</StyledTableCell> */}
                              </StyledTableRow>
                              <StyledTableRow key={planoSelected.Nome}>
                                <StyledTableCell component="th" scope="row">
                                  Internação Eletiva
                                </StyledTableCell>
                                <StyledTableCell align="center">{planoSelected.InternacaoEletivaIpiranga ? <CheckIcon id="check-green-icon"/> : ' '}</StyledTableCell>
                                <StyledTableCell align="center">{planoSelected.InternacaoEletivaPompeia ? <CheckIcon id="check-green-icon"/> : ' '}</StyledTableCell>
                                <StyledTableCell align="center">{planoSelected.InternacaoEletivaSantana ? <CheckIcon id="check-green-icon"/> : ' '}</StyledTableCell>
                                {/* <StyledTableCell align="center">{planoSelected.InternacaoEletivaCentroMedico ? <CheckIcon id="check-green-icon"/> : ' '}</StyledTableCell> */}
                              </StyledTableRow>
                              <StyledTableRow key={planoSelected.Nome}>
                                <StyledTableCell component="th" scope="row">
                                  Pronto Socorro Adulto
                                </StyledTableCell>
                                <StyledTableCell align="center">{planoSelected.PSAdultoIpiranga ? <CheckIcon id="check-green-icon"/> : ' '}</StyledTableCell>
                                <StyledTableCell align="center">{planoSelected.PSAdultoPompeia ? <CheckIcon id="check-green-icon"/> : ' '}</StyledTableCell>
                                <StyledTableCell align="center">{planoSelected.PSAdultoSantana ? <CheckIcon id="check-green-icon"/> : ' '}</StyledTableCell>
                                {/* <StyledTableCell align="center">{planoSelected.PSAdultoCentroMedico ? <CheckIcon id="check-green-icon"/> : ' '}</StyledTableCell> */}
                              </StyledTableRow>
                              <StyledTableRow key={planoSelected.Nome}>
                                <StyledTableCell component="th" scope="row">
                                  Pronto Socorro Infantil
                                </StyledTableCell>
                                <StyledTableCell align="center">{planoSelected.PSInfantilIpiranga ? <CheckIcon id="check-green-icon"/> : ' '}</StyledTableCell>
                                <StyledTableCell align="center">{planoSelected.PSInfantilPompeia ? <CheckIcon id="check-green-icon"/> : ' '}</StyledTableCell>
                                <StyledTableCell align="center">{planoSelected.PSInfantilSantana ? <CheckIcon id="check-green-icon"/> : ' '}</StyledTableCell>
                                {/* <StyledTableCell align="center">{planoSelected.PSInfantilCentroMedico ? <CheckIcon id="check-green-icon"/> : ' '}</StyledTableCell> */}
                              </StyledTableRow>
                              <StyledTableRow key={planoSelected.Nome}>
                                <StyledTableCell component="th" scope="row">
                                Exames de Imagem
                                </StyledTableCell>
                                <StyledTableCell align="center">{planoSelected.ExamesIpiranga ? <CheckIcon id="check-green-icon"/> : ' '}</StyledTableCell>
                                <StyledTableCell align="center">{planoSelected.ExamesPompeia ? <CheckIcon id="check-green-icon"/> : ' '}</StyledTableCell>
                                <StyledTableCell align="center">{planoSelected.ExamesSantana ? <CheckIcon id="check-green-icon"/> : ' '}</StyledTableCell>
                                {/* <StyledTableCell align="center">{planoSelected.ExamesCentroMedico ? <CheckIcon id="check-green-icon"/> : ' '}</StyledTableCell> */}
                              </StyledTableRow>
                              <StyledTableRow key={planoSelected.Nome}>
                                <StyledTableCell component="th" scope="row">
                                  Consultas
                                </StyledTableCell>
                                <StyledTableCell align="center">{planoSelected.ConsultasIpiranga ? <CheckIcon id="check-green-icon"/> : ' '}</StyledTableCell>
                                <StyledTableCell align="center">{planoSelected.ConsultasPompeia ? <CheckIcon id="check-green-icon"/> : ' '}</StyledTableCell>
                                <StyledTableCell align="center">{planoSelected.ConsultasSantana ? <CheckIcon id="check-green-icon"/> : ' '}</StyledTableCell>
                                {/* <StyledTableCell align="center">{planoSelected.ConsultasCentroMedico ? <CheckIcon id="check-green-icon"/> : ' '}</StyledTableCell> */}
                              </StyledTableRow>
                              <StyledTableRow key={planoSelected.Nome}>
                                <StyledTableCell component="th" scope="row">
                                  Quimioterapia
                                </StyledTableCell>
                                <StyledTableCell align="center">{planoSelected.QuimioterapiaAmbulatorialIpiranga ? <CheckIcon id="check-green-icon"/> : ' '}</StyledTableCell>
                                <StyledTableCell align="center">{planoSelected.QuimioterapiaAmbulatorialPompeia ? <CheckIcon id="check-green-icon"/> : ' '}</StyledTableCell>
                                <StyledTableCell align="center">{planoSelected.QuimioterapiaAmbulatorialSantana ? <CheckIcon id="check-green-icon"/> : ' '}</StyledTableCell>
                                {/* <StyledTableCell align="center">{planoSelected.QuimioterapiaAmbulatorialCentroMedico ? <CheckIcon id="check-green-icon"/> : ' '}</StyledTableCell> */}
                              </StyledTableRow>
                              <StyledTableRow key={planoSelected.Nome}>
                                <StyledTableCell component="th" scope="row">
                                Exames laboratoriais
                                </StyledTableCell>
                                <StyledTableCell align="center">{planoSelected.ColetaExamesLaboratoriaisIpiranga ? <CheckIcon id="check-green-icon"/> : ' '}</StyledTableCell>
                                <StyledTableCell align="center">{planoSelected.ColetaExamesLaboratoriaisPompeia ? <CheckIcon id="check-green-icon"/> : ' '}</StyledTableCell>
                                <StyledTableCell align="center">{planoSelected.ColetaExamesLaboratoriaisSantana ? <CheckIcon id="check-green-icon"/> : ' '}</StyledTableCell>
                                {/* <StyledTableCell align="center">{planoSelected.ColetaExamesLaboratoriaisCentroMedico ? <CheckIcon id="check-green-icon"/> : ' '}</StyledTableCell> */}
                              </StyledTableRow>
                            </TableBody>
                          </Table>
                        </TableContainer>    
                    </div>

                    <h8 className="h2-gray-center">Para informações quanto a cobertura, entrar em contato com nossa Central de Agendamento - (11) 3172-6800</h8>
                  </>
                )
              ) : null}
            </div>
          </div>
        )}
      </section>
    </>
  );
};

export default IframeCobertura;
